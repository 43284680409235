import {Button, Modal, Popconfirm, Table, Typography} from "antd";
import TextArea from "antd/es/input/TextArea";
import {useApi} from "../ApiProvider";
import {useEffect, useState} from "react";
import {Memory} from "../../generated/api";
import ReactMarkdown from "react-markdown";
import {DeleteOutlined} from "@ant-design/icons";

const {Text} = Typography;

interface MemoryProps {
    user: any;
    visible: boolean;
    onClose: any;
}

export function MemoryViewer(props: MemoryProps) {
    const [memory, setMemory] = useState<Memory[]>([]);
    const [editingValue, setEditingValue] = useState<Record<string, string>>({});
    const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
    const [loading, setLoading] = useState(false);
    const api = useApi();

    useEffect(() => {
        if (props.visible) {
            api.getMemoryForUser().then((mem) => {
                setMemory(mem.data);
            });
            setEditingValue({});
        }
    }, [props.visible]);

    const handleEdit = (key: number, value: string) => {
        setEditingValue({...editingValue, [key]: value});
    };

    const cancelEdit = (key: number) => {
        setEditingValue((prev) => {
            const updated = {...prev};
            delete updated[key];
            return updated;
        });
    };

    const saveChanges = async (key: number) => {
        const newValue = editingValue[key];
        setLoading(true);
        try {
            const mem = memory.map((item) => (item.id === key ? {...item, value: newValue} : item))
            const updatedMem = mem.filter(m => m.id === key)[0]
            await api.setMemoryForUser({id: updatedMem.id!, key: updatedMem.key, value: updatedMem.value})
            setMemory(mem);
            cancelEdit(key);
        } catch (error) {
            console.error("Failed to save changes:", error);
        } finally {
            setLoading(false);
        }
    };

    const deleteMemory = async (id: number) => {
        setLoading(true);
        try {
            await api.deleteMemory(id)
            setMemory((prev) => prev.filter((item) => item.id !== id));
        } catch (error) {
            console.error("Failed to delete memory:", error);
        } finally {
            setLoading(false);
        }
    };

    const toggleRowExpansion = (id: number) => {
        setExpandedRows((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    const columns = [
        {
            title: "Key",
            dataIndex: "key",
            key: "key",
            render: (text: string) => <Text style={{minWidth: 200}}>{text}</Text>,
        },
        {
            title: "Value",
            dataIndex: "value",
            key: "value",
            render: (value: string, record: Memory) => {
                const isEditing = record.id! in editingValue;
                const isExpanded = expandedRows.has(record.id!);
                const truncatedValue = value.length > 100 ? `${value.slice(0, 100)}...` : value;

                return isEditing ? (
                    <div>
                        <TextArea
                            value={editingValue[record.id!] ?? value}
                            onChange={(e) => handleEdit(record.id!, e.target.value)}
                            autoSize={{minRows: 2, maxRows: 6}}
                        />
                        <div style={{marginTop: 8}}>
                            <Button
                                type="primary"
                                onClick={() => saveChanges(record.id!)}
                                loading={loading}
                                style={{marginRight: 8}}
                            >
                                Save
                            </Button>
                            <Button onClick={() => cancelEdit(record.id!)}>Cancel</Button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="markdown-container">
                            <ReactMarkdown>{isExpanded ? value : truncatedValue}</ReactMarkdown>
                        </div>
                        {value.length > 100 && (
                            <Button
                                type="link"
                                onClick={() => toggleRowExpansion(record.id!)}
                                style={{padding: 0}}
                            >
                                {isExpanded ? "Show Less" : "Show More"}
                            </Button>
                        )}
                        <Button
                            type="link"
                            onClick={() => handleEdit(record.id!, value)}
                            style={{padding: 0, marginLeft: 10}}
                        >
                            Edit
                        </Button>
                    </div>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, record: Memory) => (
                <Popconfirm
                    title="Are you sure you want to delete this memory?"
                    onConfirm={() => deleteMemory(record.id!)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button danger type="text">
                        <DeleteOutlined/>
                    </Button>
                </Popconfirm>
            ),
        },
    ];

    return (
        <Modal
            title="Memory Viewer"
            open={props.visible}
            onCancel={props.onClose}
            footer={[
                <Button key="close" type="primary" onClick={props.onClose}>
                    Close
                </Button>,
            ]}
            width={1000}
        >
            <Table
                dataSource={memory}
                columns={columns}
                bordered
                pagination={false}
                size="small"
                rowKey="id"
            />
        </Modal>
    );
}
