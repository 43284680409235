import React, {useEffect, useRef, useState} from 'react';
import * as monaco from 'monaco-editor';
import {configureMonacoYaml} from 'monaco-yaml';
import {Button, Input, message, Modal} from 'antd';
import schema from './workflowschema.json';
import yaml from 'js-yaml';
import {WorkflowConfigDto} from '../../generated/api';
import {useApi} from '../ApiProvider';
import {LeftOutlined} from '@ant-design/icons';

window.MonacoEnvironment = {
    getWorker(moduleId, label) {
        switch (label) {
            case 'yaml':
                return new Worker(new URL('monaco-yaml/yaml.worker', import.meta.url));
            default:
                throw new Error(`Unknown label ${label}`);
        }
    }
};

configureMonacoYaml(monaco, {
    validate: true,
    enableSchemaRequest: true,
    format: true,
    hover: true,
    completion: true,
    schemas: [
        {
            // If YAML file is opened matching this glob
            fileMatch: ['**/yamlToConfig.yaml'],
            // @ts-ignore
            schema: schema,
            uri: 'https://github.com/remcohaszing/monaco-yaml/blob/HEAD/examples/demo/src/schema.json'
        }
    ]
});

const yamlModel = monaco.editor.createModel(
    '',
    undefined,
    monaco.Uri.parse('file:///yamlToConfig.yaml')
);

const exampleWorkflow = 'name: \n' +
    'info: \n' +
    'description: \n' +
    'pricePerRun: \n' +
    'shortNamePrompt: \n' +
    'exampleCase: >\n' +
    '   \n' +
    'validation:\n' +
    '  prompt: >\n' +
    '    \n' +
    'ui:\n' +
    '  inputDescription: >\n' +
    '    \n' +
    'summarization:\n' +
    '  prompt: >\n' +
    '       \n' +
    '  refine: >\n' +
    '       \n' +
    'agents:\n' +
    '  - tabName: \n' +
    '    prompt: > \n' +
    '       \n' +
    '    inputVariables:\n' +
    '      \n' +
    '    outputVariable: \n' +
    '\n' +
    '  - tabName: \n' +
    '    prompt: > \n' +
    '        \n' +
    '    inputVariables:\n' +
    '      \n' +
    '    outputVariable: \n';


export function WorkflowEditor(props: {
    contentToEdit?: string
    checkClose: (value: boolean) => void;
    hideEditor: (value: boolean) => void;
}) {
    const api = useApi();
    const ref = useRef(null);
    const editorInstance = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
    const [editorValue, setEditorValue] = useState(props.contentToEdit ?? exampleWorkflow);
    const [markerMessages, setMarkersMessages] = useState<string[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [promptGenerationLoading, setPromptGenerationLoading] = useState(false);

    const submitPrompt = () => {
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        try {
            setPromptGenerationLoading(true);
            await api.promptToConfig({prompt: inputValue})
                .then(res => {
                    setEditorValue(res.data)
                    message.success('Workflow generated successfully');
                }).finally(() => {
                    setPromptGenerationLoading(false);
                });
        } catch (error) {
            message.error('Failed to send text');
        }
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleInputChange = (e: any) => {
        setInputValue(e.target.value);
    };

    useEffect(() => {
        if (!editorInstance.current) {
            const yamlInstance = monaco.editor.create(ref.current!, {
                value: editorValue,
                model: yamlModel,
                theme: 'vs-dark',
                language: 'yaml',
                fontSize: 14,
            });
            editorInstance.current = yamlInstance;

            yamlInstance.onDidChangeModelContent(() => {
                props.checkClose(props.contentToEdit !== yamlModel.getValue());
            });
            monaco.editor.onDidChangeMarkers(() => {
                setMarkersMessages(monaco.editor.getModelMarkers({}).map(mark => mark.message))
            });
        }
    }, []);

    useEffect(() => {
        if (editorInstance.current) {
            yamlModel.setValue(editorValue);
        }
    }, [editorValue]);

    const handleSave = () => {
        if (monaco.editor.getModelMarkers({}).length === 0) {
            console.log('conf bef')
            const config: WorkflowConfigDto = yaml.load(yamlModel.getValue()) as WorkflowConfigDto;
            console.log('conf after')
            if (config) {
                api.saveWorkflowConfig(config)
                    .then(() => {
                        message.info('Config successfully saved');
                        props.hideEditor(true);
                    })
                    .catch(er => {
                        message.error(er.response.data);
                    });
            } else {
                message.error("Error. Couldn't parse config! Please, check yaml");
            }
        } else {
            message.error(monaco.editor.getModelMarkers({}).map(mar => mar.message).concat('\n')).then(r => {
            });
        }
    };

    return (
        <>
            <div style={{display: 'flex', marginTop: '10px', flexDirection: 'column', height: '100vh'}}>
                <div ref={ref} style={{flex: 1, width: '100%', border: '1px solid #d9d9d9'}}/>
                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px'}}>
                    <div>
                        <Button style={{margin: '5px'}} type="link" size="large"
                                onClick={() => props.hideEditor(false)}>
                            <LeftOutlined/> Back
                        </Button>
                        <Button loading={promptGenerationLoading} onClick={submitPrompt} style={{margin: '5px'}}>
                            Create from Prompt
                        </Button>
                        <Button type="primary" size="large" onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                </div>
                <Modal
                    title="Generate Workflow Configuration"
                    open={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    confirmLoading={promptGenerationLoading}
                >
                    <Input.TextArea
                        placeholder="Enter a prompt to generate workflow config"
                        value={inputValue}
                        onChange={handleInputChange}
                        rows={4}
                    />
                </Modal>
            </div>
        </>
    );
}
