import React, {useEffect, useState} from 'react';
import {
    CloseOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    FormOutlined,
    MenuOutlined,
    ShareAltOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Avatar, Button, Dropdown, Input, Layout, Menu, message, Modal, Tooltip} from 'antd';
import {useApi} from "./ApiProvider";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {MessageHeaderDto} from "../generated/api";
import {useKeycloak} from "@react-keycloak/web";
import NewCasePage from "./NewCasePage";
import {SupportModal} from "./chat/SupportModal";
import {ReflexContainer, ReflexElement, ReflexSplitter} from "react-reflex";
import ChatInterface from "./ChatInterface";
import {AccountDetails} from "./chat/AccountDetails";
import Text from "antd/es/typography/Text";
import {hotjar} from "react-hotjar";
import {useMobileContext} from "./share/MobileProvider";
import {v4 as uuidv4} from "uuid";

export function Main() {
    const api = useApi();
    const keycloak = useKeycloak();
    const location = useLocation();
    const navigate = useNavigate();
    const [activeChat, setActiveChat] = useState<{ executionId: string | undefined, isNew: boolean, workflow: undefined | string }>({
        executionId: undefined,
        isNew: false,
        workflow: undefined
    });
    let {id} = useParams();
    const [chats, setChats] = useState<MessageHeaderDto[]>([]);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
    const [isAccountDetailsModalOpen, setIsAccountDetailsModalOpen] = useState(false);
    const [inputText, setInputText] = useState("");
    const [shareModalText, setShareModalText] = useState("");
    const [supportModalText, setSupportModalText] = useState("");
    const [selectedWorkflow, setSelectedWorkflow] = useState("");
    const [showNewCasePage, setShowNewCasePage] = useState(true);
    const [updateHeaders, setUpdateHeaders] = useState<string>("");
    const [showExampleCase, setShowExampleCase] = useState(false);
    const [selectedChatIdToShare, setSelectedChatIdToShare] = useState<number | undefined>();
    const {isMobile, setIsMobile} = useMobileContext();
    const [continueCaseModal, setContinueCaseModal] = useState(false);
    const [idToCaseContinue, setIdToCaseContinue] = useState('');
    const [menuVisible, setMenuVisible] = useState(false);
    const routeNames = {
        "/": "Home",
        "/logout": "Logout",
        "/manage": "Management",
        "/external/swagger": "API Documentation",
        "/waiting": "Waiting Room",
        "/denied": "Access Denied",
    };

    useEffect(() => {
        if (new URLSearchParams(window.location.search).get('external') == null) setMenuVisible(!isMobile)
    }, [isMobile]);

    useEffect(() => {
        const path = window.location.pathname;
        const prettyName = getPrettyNameFromPath(path);
        document.title = `Chat Interface | ${prettyName}`;
        if (hotjar.initialized()) {
            hotjar.stateChange(document.location.pathname);
        }
    }, [location]);

    const getPrettyNameFromPath = (path: any) => {
        const segments = path.split('/').filter(Boolean); // Splits the path and removes empty segments
        const lastSegment: string = segments.length > 0 ? segments[segments.length - 1] : '/';
        return routeNames[lastSegment as keyof typeof routeNames] ?? 'Main';
    };


    useEffect(() => {
        if (id) {
            setActiveChat({executionId: id, isNew: false, workflow: undefined})
            setShowNewCasePage(false)
        } else setActiveChat({executionId: undefined, isNew: false, workflow: selectedWorkflow})
    }, [id]);

    useEffect(() => {
        api.getMessageHeaders().then((res) => {
            setChats(res.data)
        });
    }, [api, updateHeaders]);


    function showShareModal(chatId: number) {
        setSelectedChatIdToShare(chatId);
        setIsShareModalOpen(true);
    }

    function showSupportShareModal(chatId: number) {
        setSelectedChatIdToShare(chatId);
        setIsSupportModalOpen(true);
    }

    const shareChat = () => {
        if (selectedChatIdToShare)
            api.shareMessage({messageId: selectedChatIdToShare, username: inputText}).then(res => {
                    message.success(res.data.message).then(() => {
                    })
                    setIsShareModalOpen(false)
                }
            ).catch(e => {
                message.error(e?.response?.data)
                setShareModalText(e.response?.data?.message)
            });
    };

    function deleteItem(id: number) {
        api.deleteMessage(id).then(() => {
            setChats(old => ([
                ...old.filter(x => x.id !== id)
            ]))
            navigate("/")
        })
    }

    const handleSupportModalSubmit = (description: string) => {
        if (selectedChatIdToShare) {
            api.shareWithSupport({
                messageId: selectedChatIdToShare,
                description: description
            }).then(res => {
                message.success(res.data.message).then();
                setIsSupportModalOpen(false);
            }).catch(e => {
                setSupportModalText(e.response?.data?.message);
            });
        }
    };

    const renderDropdownMenu = (chat: any) => (
        <Menu>
            <Menu.Item
                onClick={() => {
                    showSupportShareModal(chat.id);
                }}
            ><ExclamationCircleOutlined/> Support share
            </Menu.Item>
            <Menu.Item key="share" onClick={() => showShareModal(chat.id)}>
                <ShareAltOutlined/> Share
            </Menu.Item>
            <Menu.Item key="delete" onClick={(e) => {
                deleteItem(chat.id);
                e.domEvent.stopPropagation();
            }}>
                <DeleteOutlined/> Delete
            </Menu.Item>
        </Menu>
    );

    function generateAvatar() {
        if (keycloak.keycloak.tokenParsed) {
            return keycloak.keycloak.tokenParsed['preferred_username']?.substring(0, 1).toUpperCase();
        } else return ""
    }


    const getAppFileName = () => {
        return '/' + process.env.REACT_APP_LOGO_WHITE_FILE_NAME
    }

    function haveAccessRole() {
        if (keycloak.keycloak.realmAccess?.roles?.includes?.('base'))
            return true;
        else {
            window.location.href = ('/waiting');
        }
    }

    return !haveAccessRole() ? <></> : <>
        <Modal title="User's email to share with:" open={isShareModalOpen}
               afterOpenChange={() => {
                   setShareModalText('');
                   setInputText('')
               }}
               onOk={shareChat} onCancel={() => {
            setIsShareModalOpen(false);
            setInputText('');
        }}
               styles={{mask: {backgroundColor: 'rgba(0, 0, 0, 0.5)'}}}>
            <Text type="danger">{shareModalText}</Text>
            <Input placeholder="E-mail" value={inputText} onChange={e => setInputText(e.target.value)}/>
        </Modal>
        <SupportModal
            isOpen={isSupportModalOpen}
            onClose={() => setIsSupportModalOpen(false)}
            onSubmit={handleSupportModalSubmit}
            errorMessage={supportModalText}
        />
        <Modal
            title="Unfinished Case Detected"
            open={continueCaseModal}
            onOk={() => {
                setContinueCaseModal(false);
                navigate('/' + idToCaseContinue)
            }}
            onCancel={() => {
                setContinueCaseModal(false)
                setShowNewCasePage(false);
            }
            }
            okText="Continue Unfinished Case"
            cancelText="Create New Case"
        >
            <p>An unfinished case exists for this workflow. Would you like to continue with the existing case or start a
                new one?</p>
        </Modal>
        <AccountDetails
            isOpen={isAccountDetailsModalOpen}
            onClose={() => setIsAccountDetailsModalOpen(false)}
            onSubmit={handleSupportModalSubmit}
        />
        <Layout hasSider={true}>
            <ReflexContainer orientation={"vertical"}>
                {menuVisible ? (
                    <ReflexElement minSize={150} maxSize={600} size={isMobile ? 220 : 250}>
                        <Layout.Sider width={"100%"} style={{height: "100%"}}>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1, width: "100%"}}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                    padding: 6,
                                    height: "100%",
                                    overflow: "auto"
                                }}>
                                    <Menu theme="dark" mode="inline"
                                          selectedKeys={activeChat.executionId ? [activeChat.executionId.toString()] : ['new']}
                                          items={[
                                              {
                                                  key: 'new',
                                                  className: 'new-list-item',
                                                  label: <>
                                                      <div style={{
                                                          padding: '5px',
                                                          marginRight: 5,
                                                          display: 'flex',
                                                          justifyContent: 'space-between',
                                                          alignItems: 'center',
                                                          height: '100%'
                                                      }}>
                                                          <img src={getAppFileName()} alt="Company Logo"
                                                               style={{maxWidth: '125px'}}/>
                                                          <Tooltip title="New case">
                                                              <FormOutlined/>
                                                          </Tooltip>
                                                      </div>
                                                  </>
                                              },
                                              ...chats.filter(m => !m.isShared).map(chat => ({
                                                  key: chat.executionId,
                                                  label: <div className={'list-item'}>
                                                      <span
                                                          className={'title'}>{chat.shortName ? chat.shortName : chat.input}</span>
                                                      <div>
                                                          {activeChat.executionId == chat.executionId && (
                                                              <div>
                                                                  <Dropdown trigger={['click']}
                                                                            overlay={renderDropdownMenu(chat)}>
                                                                      <MenuOutlined/>
                                                                  </Dropdown>
                                                              </div>
                                                          )}
                                                      </div>
                                                  </div>,
                                                  icon: <UserOutlined style={{paddingLeft: '6px'}}/>
                                              }))
                                              ,
                                          ]} onClick={e => {
                                        if (e.key === 'new') {
                                            navigate('/')
                                            setShowNewCasePage(true);
                                        } else {
                                            navigate('/' + e.key)
                                            setShowNewCasePage(false);
                                        }
                                        const target = e.domEvent.target as HTMLElement;
                                        if (isMobile && target.tagName.toLowerCase() === 'span' && target.classList.contains('title')) {
                                            setMenuVisible(false);
                                        }
                                    }}></Menu>
                                    {chats.filter(m => m.isShared).length !== 0 && (
                                        <div style={{
                                            display: 'flex', flexDirection: 'column', flex: 1,
                                            padding: 6
                                        }}>
                                            <p style={{
                                                display: 'flex',
                                                flexDirection: 'column-reverse',
                                                flex: 1,
                                                padding: 6,
                                                color: 'white'
                                            }}>
                                                Shared with me:
                                            </p>
                                            <Menu theme="dark" mode="inline"
                                                  selectedKeys={activeChat.executionId ? [activeChat.executionId.toString()] : ['new']}
                                                  items={[
                                                      ...chats.filter(m => m.isShared).map(chat => ({
                                                          key: chat.executionId.toString() ?? "",
                                                          label: (
                                                              <div className={'list-item'}>
                                                                  <span
                                                                      className={'title'}>{chat.shortName ? chat.shortName : chat.input}</span>
                                                                  <span className={'delete'}
                                                                        onClick={() => showShareModal(chat.id)}>
                                                        <ShareAltOutlined/></span>
                                                                  <span className={'delete'} onClick={e => {
                                                                      deleteItem(chat.id)
                                                                      e.stopPropagation()
                                                                  }}><DeleteOutlined/></span>
                                                              </div>
                                                          ),
                                                          icon: <UserOutlined/>
                                                      })),
                                                  ]} onClick={e => setActiveChat({
                                                executionId: e.key,
                                                isNew: false,
                                                workflow: undefined
                                            })}/>
                                        </div>
                                    )}
                                </div>

                                <div
                                    style={{padding: 5}}
                                >
                                    <Menu theme="dark" selectedKeys={[]} className={"profile"} mode="vertical" items={[
                                        {
                                            onClick: () => {
                                                setIsAccountDetailsModalOpen(true)
                                            },
                                            key: 'profile',
                                            label: 'Profile',
                                            icon: <Avatar style={{verticalAlign: 'middle'}}>{generateAvatar()}</Avatar>,
                                            theme: 'light',
                                            className: 'profile-menu',
                                        }
                                    ]}/>
                                </div>
                            </div>
                        </Layout.Sider>
                    </ReflexElement>) : null}
                {menuVisible ? <ReflexSplitter/> : null}
                <ReflexElement>
                    <Layout style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0}}>
                        {isMobile ? (
                            <Button type="primary" shape="circle"
                                    onClick={() => {
                                        setMenuVisible(!menuVisible)
                                    }}
                                    icon={menuVisible ? <CloseOutlined/> : <MenuOutlined/>}/>) : null}
                        <Layout.Content>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1, gap: 5}}>
                                {showNewCasePage ? <NewCasePage
                                    createNewChat={
                                        async (workflow, exampleCase) => {
                                            const unfinishedRuns = await api.getUnfinishedRuns(workflow)
                                            setShowExampleCase(exampleCase);
                                            setSelectedWorkflow(workflow);
                                            if (unfinishedRuns.data.length > 0) {
                                                setIdToCaseContinue(unfinishedRuns.data[0].executionId)
                                                setContinueCaseModal(true)
                                            } else {
                                                setShowNewCasePage(false);
                                            }
                                        }
                                    }/> : <ChatInterface workflow={selectedWorkflow}
                                                         showMenu={(b: boolean) => setMenuVisible(b)}
                                                         activeChat={activeChat.executionId}
                                                         deleteChat={(executionId: string) => {
                                                             const newChats = [...chats].filter(ch => ch.executionId != executionId)
                                                             setChats(newChats)
                                                         }}
                                                         setActiveChat={
                                                             executionId => setActiveChat({
                                                                 executionId: executionId,
                                                                 isNew: true,
                                                                 workflow: selectedWorkflow,
                                                             })
                                                         }
                                                         exampleCase={showExampleCase}
                                                         isNewChat={activeChat.isNew}
                                                         saveNewChat={
                                                             message => {
                                                                 setChats(oldChats => {
                                                                     if (oldChats.filter(c => c.groupId === message.groupId).length === 0) {
                                                                         return [message, ...oldChats.filter(x => x.id !== message.id)]
                                                                     }
                                                                     return oldChats
                                                                 })
                                                                 setActiveChat({
                                                                     executionId: message.executionId,
                                                                     isNew: true,
                                                                     workflow: selectedWorkflow
                                                                 })
                                                             }
                                                         }
                                                         updateHeaders={
                                                             () => {
                                                                 setUpdateHeaders(uuidv4())
                                                             }
                                                         }
                                />
                                }
                            </div>
                        </Layout.Content>
                    </Layout>
                </ReflexElement>
            </ReflexContainer>
        </Layout>
    </>

}
