import React, {useEffect, useState} from 'react';
import './App.scss';
import {ReactKeycloakProvider} from "@react-keycloak/web";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Keycloak, {KeycloakInitOptions} from "keycloak-js";
import {ApiProvider} from "./ApiProvider";
import {Spin} from "antd";
import {Logout} from "./Logout";
import {Main} from "./Main";
import {AdminInterface} from "./admin/AdminInterface";
import {SwaggerExternalApi} from "./swagger/SwaggerExternalApi";
import WaitingPage from "./chat/WaitingPage";
import NoAccessPage from "./share/NoAccessPage";
import {MobileProvider} from "./share/MobileProvider";
import DataLoader from "./share/DataLoader";
import {init} from "@sentry/react";
import InternalChat from "./chat/InternalChat";
import {hotjar} from "react-hotjar";

function App(props: { token?: string, refresh?: string }) {
    const [isServerDown, setServerDown] = useState(false);
    const [initOptions, setInitOptions] = useState<KeycloakInitOptions>({
        pkceMethod: 'S256'
    })
    const keycloak = new Keycloak({
        url: process.env.REACT_APP_KEYCLOAK_URL,
        realm: process.env.REACT_APP_REALM ?? "",
        clientId: process.env.REACT_APP_CLIENT_ID ?? ""
    })

    useEffect(() => {
        if (props.token) {
            setInitOptions({
                pkceMethod: 'S256',
                onLoad: 'login-required',
                checkLoginIframe: !props.token,
                token: props.token,
                refreshToken: props.refresh,
            })
        }
        const faviconPath = process.env.REACT_APP_FAVICON_PATH as string;
        let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        if (link && faviconPath) link.href = faviconPath;
    }, []);
    const loadingComponent = (
        <div className={"loading"}><Spin/></div>
    )

    const router = createBrowserRouter([
        {
            path: "/:id?",
            element: <Main/>,
        }, {
            path: "/logout",
            element: <Logout keycloak={keycloak}/>
        },
        {
            path: "/manage",
            element: <AdminInterface/>
        },
        {
            path: "/external/swagger",
            element: <SwaggerExternalApi/>
        }
        ,
        {
            path: "/waiting",
            element: <WaitingPage/>
        }
        ,
        {
            path: "/chat",
            element: <InternalChat chatName={'AI assistant'} executionId={'executionIdFromProps'} tabName={''}/>
        }
        ,
        {
            path: "/denied",
            element: <NoAccessPage/>
        },
        {
            path: "/fhir/callback",
            element: <DataLoader/>
        }

    ]);


    // Automatic page refresh when server is down
    useEffect(() => {
        if (isServerDown) {
            window.location.reload();
        }
    }, [isServerDown]);

    return <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={initOptions}
        LoadingComponent={loadingComponent}
        isLoadingCheck={keycloak => !keycloak.authenticated}
        onEvent={(event, error) => {
            if (error) {
                setServerDown(true);
            } else {
                if (!keycloak.authenticated) {
                    keycloak.login()
                }
            }
            if (event === 'onAuthSuccess') {
                // if (props.metadata.gaTag) {
                //   ReactGA.initialize(props.metadata.gaTag, {
                //     gaOptions: {
                //       user_id: keycloak.tokenParsed?.preferred_username,
                //       // authUsername: keycloak.tokenParsed?.preferred_username,
                //       // authUserId: keycloak.tokenParsed?.preferred_username.replaceAll(/[@.]/g, "_")
                //     }
                //   })
                //   ReactGA.set({
                //     user_properties: {
                //       authUsername: keycloak.tokenParsed?.preferred_username,
                //       authUserId: keycloak.tokenParsed?.preferred_username.replaceAll(/[@.]/g, "_")
                //     }
                //   })
                // }
                if (process.env.REACT_APP_HOT_JAR_KEY) {
                    const hj = process.env.REACT_APP_HOT_JAR_KEY.split("-")
                    hotjar.initialize(Number(hj[0]), Number(hj[1]))
                    hotjar.identify(keycloak.tokenParsed?.preferred_username,
                        {
                            authUsername: keycloak.tokenParsed?.preferred_username,
                            authUserId: keycloak.tokenParsed?.preferred_username.replaceAll(/[@.]/g, "_")
                        })
                }
                // if (process.env.REACT_APP_SENTRY_DSN) {
                init({
                    dsn: "https://12ba26e7e0563476cbcbe4175cdf28a6@o4507177180135424.ingest.us.sentry.io/4507177185050624",
                    integrations: [],
                })
                // }
            }
        }}
    >
        <ApiProvider keycloak={keycloak}>
            <MobileProvider>
                <RouterProvider router={router}/>
            </MobileProvider>
        </ApiProvider>
    </ReactKeycloakProvider>

}

export default App;
